export const parseBillPaidToDB = (paidStatus) => {
  switch(paidStatus) {
    case "paid": 
      return true;
    
    case "unpaid": 
      return false;
    
    default: 
      return null;
  }
}