import React, { useContext, createContext, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import dayjs from 'dayjs'

import { DAILY_BILL, DAILY_REPORT, MONTHLY_REPORT } from 'query'

const ReportContext = createContext(null)

const ReportProvider = ({ children }) => {
  const apolloClient = useApolloClient()
  const [reportLoading, setReportLoading] = useState('');

  const getDailyFullReport = async(date) => {
    setReportLoading('show-daily-full-report');
    try {
      let result = await apolloClient.query({
        query: DAILY_BILL,
        variables: { date }
      })
      return result
    }catch(err){
      console.log("ReportProvider getDailyFullReport", err)
    } finally {
      setReportLoading('');
    }
  }

  const getDailyReport = async(date) => {
    setReportLoading('show-daily-summary');
    try {
      let result = await apolloClient.query({
        query: DAILY_REPORT,
        variables: { date }
      })
      return result
    }catch(err){
      console.log("ReportProvider getDailyReport", err)
    } finally {
      setReportLoading('');
    }
  }

  const getMonthlyReport = async(month = dayjs().subtract(1, "months").format('MMMM'), year = dayjs().format('YYYY')) => {
    setReportLoading('show-monthly-report');
    try {
      let result = await apolloClient.query({
        query: MONTHLY_REPORT,
        variables: { month, year }
      })
      return result
    }catch(err){
      console.log("ReportProvider getMonthlyReport", err)
    } finally {
      setReportLoading('');
    }
  }

  return (
    <ReportContext.Provider
      value={{
        reportLoading,
        getDailyFullReport,
        getDailyReport,
        getMonthlyReport
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}

const useReport = () => {
  const clientUser = useContext(ReportContext)
  if (clientUser == null) {
    throw new Error('ReportContext() called outside of a AuthProvider?')
  }
  return clientUser
}

export { ReportProvider, useReport }
