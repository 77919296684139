import React, { useContext, useState, createContext } from 'react'
import { useApolloClient } from '@apollo/client'

import { DASHBOARD } from 'query'

const DashboardContext = createContext(null)

const DashboardProvider = ({ children }) => {
  const apolloClient = useApolloClient()
  const [dashboardInfo, setDashboardInfo] = useState({})
  const [dashboardLoading, setDashboardLoading] = useState(false)

  const getDashboard = async() => {
    try {
      setDashboardLoading(true);

      let result = await apolloClient.query({
        query: DASHBOARD,
      })

      setDashboardInfo(result?.data?.dashboard)
    }catch(err){
      console.log("DashboardProvider getDashboard", err);
    } finally {
      setDashboardLoading(false);
    }
  }

  return (
    <DashboardContext.Provider
      value={{
        dashboardInfo,
        dashboardLoading,
        getDashboard,
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

const useDashboard = () => {
  const dashboard = useContext(DashboardContext)
  if (dashboard == null) {
    throw new Error('dashboard() called outside of a AuthProvider?')
  }
  return dashboard
}

export { DashboardProvider, useDashboard }
