import React from 'react';
import { CSpinner } from '@coreui/react-pro'

const FullScreenSpinner = ({ open }) => {

  if (!open) return <div />;

  return (
    <div className="full-screen-spinner">
      <CSpinner className='spinner' color="primary" />
    </div>
  )
};

export default FullScreenSpinner