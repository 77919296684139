import React, { Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useClientUser } from 'contexts/ClientUserProvider'
import { useSelector } from 'react-redux'
import { useColorModes } from '@coreui/react-pro'
import FullScreenSpinner from 'components/FullScreenSpinner'
import './scss/style.scss'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login'))

export default function App() {
  const { isLoggedIn } = useClientUser();
  const { isColorModeSet, setColorMode } = useColorModes('coreui-pro-react-admin-template-theme-default');
  const storedTheme = useSelector((state) => state.theme)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }

    if (isColorModeSet()) {
      return
    }

    setColorMode(storedTheme)
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<FullScreenSpinner open />}>
        {isLoggedIn ? 
          <Routes>
            <Route path="*" element={<DefaultLayout />} />
            <Route path="/" element={<Navigate to="dashboard" replace />} />
          </Routes>    
        :
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        }
      </Suspense>
    </BrowserRouter>
  )
}