import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client'
import { removeTypenameFromMutationLink } from 'apollo-remove-typename-mutation-link'
import { RetryLink } from '@apollo/client/link/retry'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import config from './config'

const GRAPHQL_HTTP_ENDPOINT = config.GRAPHQL_HTTP

// Http Link
const httpLink = new HttpLink({
  uri: GRAPHQL_HTTP_ENDPOINT,
  credentials: 'include',
});

// Retry Link
const retryLink = new RetryLink({
  delay: {
    initial: 1000,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  }
});

// Error Link
const errorLink = onError((err) => {
  if (err.graphQLErrors)
    err.graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error on lib/apollo.js ]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
      // alert(message)
    })
  if (err.networkError) {
    console.log(`[Network error]`, err.networkError)
    // alert('Network Error',` ${err.networkError}`)
  }
  console.log(err)
});

// Auth Link
const authLink = setContext(async (operation, { headers }) => {
  const token = localStorage.getItem('@authToken');

  return {
    headers: { ...headers, token },
  }
});

// Apollo Server
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([removeTypenameFromMutationLink, errorLink, retryLink, authLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-catch',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});