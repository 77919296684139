import { gql } from '@apollo/client'

export const PAYMENT_HISTORIES = gql`
  query ($from_date: String, $to_date: String, $payment_type: String) {
    paymentReport(from_date: $from_date, to_date: $to_date, payment_type: $payment_type)
  }
`

export const DAILY_BILL = gql`
  query ($date: String) {
    dailyBill(date: $date)
  }
`

export const DAILY_REPORT = gql`
  query ($date: String) {
    dailyReport(date: $date)
  }
`

export const MONTHLY_REPORT = gql`
  query ($month: String, $year:String) {
    monthlyReport(month: $month, year: $year)
  }
`
