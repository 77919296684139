import React, { useContext, createContext, useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'

import { SIGN_IN, GET_CURRENT_USER, CHANGE_PASSWORD } from 'query'

const ClientUserContext = createContext(null)

const ClientUserProvider = ({ children }) => {
  const apolloClient = useApolloClient()

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [clientUserData, setClientUserData] = useState(null)
  const [store, setStore] = useState({})
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [userLoading, setUserLoading] = useState(false);
  
  useEffect(() => {
    getClientUser();
  }, [])

  const getClientUser = async() => {
    setUserLoading('fetch-user');
    try {
      let result = await apolloClient.query({
        query: GET_CURRENT_USER,
      })

      if (result?.data?.currentUser) setUserInfo(result?.data?.currentUser);
    } catch(err) {
      console.log("ClientUserProvider getClientUser", err);
      signOut();
    } finally {
      setUserLoading(false);
    }
  }

  const signIn = async (username, password) => {
    try {
      setUserLoading('sign-in');

      let result = await apolloClient.mutate({
        mutation: SIGN_IN,
        variables: { username, password },
      })

      const userInfo = result?.data?.signIn;
            
      if(userInfo) await setUserInfo(userInfo)

      return userInfo;
    } catch (err) {
      console.log("ClientUserProvider signIn", err)
      alert(err)
    } finally {
      setUserLoading(false);
    }
  }

  const signOut = async () => {
    localStorage.removeItem('@authToken')
    setStore([])
    setProducts([])
    setCategories([])

    setClientUserData({})
    setIsLoggedIn(false)
  }

  const changePassword = async (newPassword, confirmPassword) => {
    try {
      setUserLoading('change-password');

      let result = await apolloClient.mutate({
        mutation: CHANGE_PASSWORD,
        variables: { newPassword, confirmPassword },
      })

      const isSuccess = result?.changePassword?.status === "success" || false;
      if(isSuccess) return true;
      if(!isSuccess) throw result;
    } catch (err) {
      console.log("ClientUserProvider changePassword", err)
    } finally {
      setUserLoading(false);
    }
  }

  const setUserInfo = async (data) => {
    const { token, store, products, categories, last_bill_no, ...rest } = data

    if(token) localStorage.setItem('@authToken',token)
    setStore(store)
    setProducts(products)
    setCategories(categories)

    setClientUserData(rest)
    setIsLoggedIn(true)
  }

  return (
    <ClientUserContext.Provider
      value={{
        isLoggedIn,
        clientUserData,
        store,
        userLoading,
        signIn, 
        signOut,
        changePassword,
      }}
    >
      {children}
    </ClientUserContext.Provider>
  )
}

const useClientUser = () => {
  const clientUser = useContext(ClientUserContext)
  if (clientUser == null) {
    throw new Error('clientUser() called outside of a AuthProvider?')
  }
  return clientUser
}

export { ClientUserProvider, useClientUser }
