import React, { useContext, useState, createContext } from 'react'
import { useApolloClient } from '@apollo/client'
import { parseBillPaidToDB } from 'utils/backend'

import { BILLS, GET_INSUFFICIENT_BILL } from 'query'
import dayjs from 'dayjs'

const BillContext = createContext(null)

const paidStatus = [
  { label: 'All', value: ""  },
  { label: 'Paid', value: "paid"  },
  { label: 'Unpaid', value: "unpaid" },
]

const BillProvider = ({ children }) => {
  const apolloClient = useApolloClient();

  const [billList, setBillList] = useState([]);
  const [insufficientBillList, setInsufficientBillList] = useState([]);
  const [processingBillList, setProcessingBillList] = useState([]);
  const [completedBillList, setCompletedBillList] = useState([]);
  const [unpaidBillList, setUnpaidBillList] = useState([]);
  const [billLoading, setBillLoading] = useState(false)

  const getBillList = async ({ fromDate, toDate, billStatus, billPaid }) => {
    setBillLoading('bill-record')
    
    try {
      let result = await apolloClient.query({
        query: BILLS,
        variables: { billDateDuration: { from: fromDate, to: toDate }, billStatus, billPaid: parseBillPaidToDB(billPaid) }
      })

      setBillList(result?.data?.bills?.result ?? [])
      setBillLoading(false)
    }catch(err){
      console.log("BillProvider getBillList", err)
      setBillLoading(false)
      throw err
    }
  }

  const getInsufficientBillList = async ({ fromDate, toDate }) => {
    setBillLoading('insufficient-bill')
    
    try {
      let result = await apolloClient.query({
        query: GET_INSUFFICIENT_BILL,
        variables: { billDateDuration: { from: fromDate, to: toDate } },
      })

      setInsufficientBillList(result?.data?.getInsufficientBill?.result ?? [])
    }catch(err){
      console.log("BillProvider getInsufficientBillList", err)
    } finally {
      setBillLoading(false)
    }
  }

  const getProcessingBillList = async ({ fromDate, toDate }) => {    
    setBillLoading('processing-bill')
    
    try {
      let result = await apolloClient.query({
        query: BILLS,
        variables: { billDateDuration: { from: fromDate, to: toDate }, billStatus: "processing" }
      })
      
      setProcessingBillList(result?.data?.bills?.result ?? [])
    } catch(err) {
      console.log("BillProvider getProcessingBillList", err)
    } finally {
      setBillLoading(false)
    }
  }

  const getCompletedBillList = async ({ fromDate, toDate }) => {    
    setBillLoading('completed-bill')
    
    try {
      let result = await apolloClient.query({
        query: BILLS,
        variables: { billDateDuration: { from: fromDate, to: toDate }, billStatus: "completed" }
      })

      setCompletedBillList(result?.data?.bills?.result ?? [])
    }catch(err){
      console.log("BillProvider getCompletedBillList", err)
    } finally {
      setBillLoading(false)
    }
  }

  const getUnpaidBillList = async ({ fromDate, toDate }) => {    
    setBillLoading('unpaid-bill')
    
    try {
      let result = await apolloClient.query({
        query: BILLS,
        variables: { billDateDuration: { from: fromDate, to: toDate }, billStatus: "collected", billPaid: false,  }
      })

      setUnpaidBillList(result?.data?.bills?.result ?? [])
    }catch(err){
      console.log("BillProvider getUnpaidBillList", err)
    } finally {
      setBillLoading(false)
    }
  }

  return (
    <BillContext.Provider
      value={{
        billList,
        insufficientBillList,
        processingBillList,
        completedBillList,
        unpaidBillList,
        paidStatus,
        billLoading, setBillLoading,
        getBillList,
        getInsufficientBillList,
        getProcessingBillList,
        getCompletedBillList,
        getUnpaidBillList,
      }}
    >
      {children}
    </BillContext.Provider>
  )
}

const useBill = () => {
  const clientUser = useContext(BillContext)
  if (clientUser == null) {
    throw new Error('BillContext() called outside of a AuthProvider?')
  }
  return clientUser
}

export { BillProvider, useBill }
