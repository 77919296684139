import { gql } from '@apollo/client'
import { FEEDBACK_ATTRIBUTES } from './general'

const CLIENT_USER_ATTRIBUTES = gql`
  fragment clientUserAttributes on User {
    _id
    first_name
    last_name
    token
    store{
      _id
      name
      address1
      address2
      postcode
      city
      company_name
      company_no
      country
      phone
      last_bill_no
      email
      currency_code
      currency_left
      currency_right
      payment_types{
        code
        name
        bg_color
        color
      }
      status_types{
        name
        display_name
        bgColor
        color
        next
      }
    }
  }
`

export const GET_CURRENT_USER = gql`
  query {
    currentUser {
      ...clientUserAttributes
    }
  }
  ${CLIENT_USER_ATTRIBUTES}
`

export const SIGN_IN = gql`
  mutation ($username: String, $password: String, $loginCode: String){
    signIn(username:$username,password:$password,loginCode:$loginCode){
      ...clientUserAttributes
    }
  }${CLIENT_USER_ATTRIBUTES}
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword($newPassword: String!, $confirmPassword: String!) {
    changePassword(newPassword: $newPassword, confirmPassword: $confirmPassword) {
      ...StandardFeedback
    }
  }${FEEDBACK_ATTRIBUTES}
`
