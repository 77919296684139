import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ClientUserProvider } from './contexts/ClientUserProvider'
import { DashboardProvider } from './contexts/DashboardProvider'
import { BillProvider } from './contexts/BillProvider'
import { ReportProvider } from './contexts/ReportProvider'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import './i18n'
import { ApolloProvider } from '@apollo/client'
import { apolloClient } from 'lib/apollo'

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <ClientUserProvider>
        <DashboardProvider>
          <BillProvider>
            <ReportProvider>
              <App />
            </ReportProvider>
          </BillProvider>
        </DashboardProvider>
      </ClientUserProvider>
    </ApolloProvider>
  </Provider>,
)
