import { gql } from '@apollo/client';

const BILL_ATTRIBUTES = gql`
  fragment billAttributes on Bill {
    _id
    collection_date
    collected_at
    bill_date
    bill_items {
      category_no
      category_name
      category_display_name
      currency
      product_id
      display_name
      name
      minimum_charges
      maximum_discount
      unit
      price
      qty
      sub_total
      discount
      total_discount
      total
    }
    bill_no
    bill_type
    customer_id
    customer_name
    customer_phone
    payment_type
    status
    total_amount
    total_discount
    round_total
    total_paid
  }
`

export const BILLS = gql`
  query ($keyword: String, $billDate: String, $billDateDuration: JSON, $billStatus: String, $billPaid: Boolean, $sort: JSON) {
    bills(keyword:$keyword, billDate:$billDate, billDateDuration:$billDateDuration, billStatus:$billStatus, billPaid:$billPaid, sort:$sort) {
      totalResult,
      result{ ...billAttributes }
    }
  }${BILL_ATTRIBUTES}
`;